











import { Component, Vue } from "vue-property-decorator";
import MenuItem from "@/components/MenuItem.vue";
import { RouteName } from "@/router/types";

@Component({
  components: { MenuItem },
})
export default class Monitoring extends Vue {
  RouteName = RouteName;
}
